@import "variables";

.slider {
	opacity: 0;
	width: 100%;
	transition: background-color 1000ms linear;
	@media (min-width: $screen-sm-min) {
		height: 500px;
	}

	background: #2d373c;
	border-radius: 35px;

	@media (max-width: $screen-xs-max) {
		border-radius: 35px;
	}

	.carousel-wrapper {
		width: 100%;

		.row {
			position: relative;
			height: 500px;

			@media (max-width: $screen-xs-max) {
				height: unset;
				justify-content: center;
			}
		}
		.flex-center {
			@media (min-width: $screen-sm-min) {
				display: flex;
				justify-content: center;
			}
		}

		.slider-image {
			display: flex !important;
			align-items: center;
			align-self: center;
			justify-content: center;
			position: relative;
			height: 100%;

			@media (max-width: $screen-xs-max) {
				min-height: 300px;
			}

			img {
				position: absolute;
				top: 0;
				left: -40px;
				right: 0;
				bottom: 0;
				margin-right: auto;
				margin-top: auto;
				margin-bottom: auto;

				@media (max-width: $screen-xs-min) {
					position: relative;
					min-width: 300px;
					left: 0;
					margin-left: 0;
				}
			}
		}

		.slider-text {
			height: 100%;
			padding-bottom: 10px;
			@media (max-width: $screen-xs-min) {
				text-align: center;
				padding-bottom: 20px;
				padding-top: 20px;
			}

			h1 {
				@media (min-width: $screen-sm-min) {
					// padding-top: 50px;
				}
			}

			text-align: right;

			p {
				color: $white;
			}

			.btn-white {
				border: 2px solid $white;
				border-radius: 10px;
				color: $white;
				text-transform: uppercase;
				padding: 10px 20px;
				font-size: 14px;

				@media (max-width: $screen-xs-max) {
					margin-bottom: 2.5rem;
				}

				&:hover,
				&:active {
					color: $black;
					// box-shadow: 1px 1px 2px $green, 0 0 25px $white, 0 0 5px $white;
					background: $white;
				}
			}
		}

		.img-carousel {
			width: 100%;

			@media (max-width: 767px) {
				width: 100%;
			}
		}
	}
	.carousel-wrapper.imageTopRight {
		img {
			height: auto;
			top: 2px;
			right: 2px;
		}
		.slider-text-container {
			padding-left: 24px;
			padding-right: 24px;
		}
	}

	.carousel-wrapper.reverse {
		.slider-text-container {
			padding-left: 24px;
			padding-right: 24px;
		}
		.slider-text {
			@media (max-width: $screen-xs-min) {
				text-align: center;
				max-width: unset;
			}
			max-width: 264px;
			text-align: left;
		}
		// .slider-image {
		//   min-height: unset;
		// }
		// img {
		//   width: auto;
		//   max-width: 467px;
		//   max-height: 463px;
		//   @media (min-width: 576px) {
		//     position: relative;
		//     top: 50%;
		//     -webkit-transform: translateY(-50%);
		//     -o-transform: translateY(-50%);
		//     transform: translateY(-50%);
		//   }
		//   @media (max-width: 1200px) {
		//     width: 100%;
		//     height: auto;
		//   }
		// }
	}
}

// .has-toddlerbg {
//   background-image: url("../images/toddler-carousel-bg.png");
//   background-position: center;
//   background-size: cover;
//   overflow: hidden;
// }

.has-phoenixBg {
	background-image: url("../images/ec_phoenix_bg.jpg");
}

.has-cryptobg {
	background-image: url("../images/cryptobg.jpg");
}

.has-claimDustBg {
	background-image: url("../images/dust-carousel-banner_bg.png");
	background-position: top center;
}
// .has-plutoAllianceBg {
//   //background-image: url("../images/2.png");
//   background-image: url("../images/aftmrkt1Bg.png");
// }

.has-plutoAllianceBg.slider .carousel-wrapper .slider-image img {
	left: 0;
}

#ether-cards {
	padding: 0;
}

.os-banner {
	display: none;
	width: 100%;
	line-height: 48px;
	text-align: center;
	background-color: #be1fda;
	color: #fff;
	position: fixed;
	top: 0;
	z-index: 10200;

	a {
		margin-left: 0.5rem;
		color: #fff;
		font-weight: 600;
		transition: color 0.3s ease-out;
		&:hover {
			color: #d2d2d2;
			text-decoration: none;
		}

		img {
			max-height: 24px;
			margin-right: 0.25rem;
		}
	}

	.close-btn {
		position: absolute;
		right: 2rem;
		cursor: pointer;
		font-weight: 700;
	}
}

.os-banner-visible {
	header {
		.os-banner {
			display: block;
		}
		nav {
			top: 48px;
		}
	}

	main {
		margin-top: 148px;
	}
}

.offset-1andhalf {
	margin-left: 12%;
	@media (max-width: 768px) {
		margin-left: 0;
	}
}

.offset-lg-half {
	@media (min-width: 992px) {
		margin-right: 4%;
	}
}

@media (max-width: 992px) and (min-width: 768px) {
	.carousel-wrapper.reverse {
		img {
			height: 80%;
		}
	}
}

@media (max-width: 550px) {
	.os-banner {
		line-height: 24px;
	}
}

.imageContanier {
	height: 100%;
}

@media (max-width: 1190px) and (min-width: 750px) {
	.has-claimDustBg .slide {
		flex-direction: column;
	}
}
@media (max-width: 750px) {
	.imageContanier {
		height: 85%;
	}
}
