.slider {
  opacity: 0;
  width: 100%;
  transition: background-color 1000ms linear;
  background: #2d373c;
  border-radius: 35px;
}

@media (min-width: 768px) {
  .slider {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .slider {
    border-radius: 35px;
  }
}

.slider .carousel-wrapper {
  width: 100%;
}

.slider .carousel-wrapper .row {
  position: relative;
  height: 500px;
}

@media (max-width: 767px) {
  .slider .carousel-wrapper .row {
    height: unset;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .slider .carousel-wrapper .flex-center {
    display: flex;
    justify-content: center;
  }
}

.slider .carousel-wrapper .slider-image {
  display: flex !important;
  align-items: center;
  align-self: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

@media (max-width: 767px) {
  .slider .carousel-wrapper .slider-image {
    min-height: 300px;
  }
}

.slider .carousel-wrapper .slider-image img {
  position: absolute;
  top: 0;
  left: -40px;
  right: 0;
  bottom: 0;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

@media (max-width: 576px) {
  .slider .carousel-wrapper .slider-image img {
    position: relative;
    min-width: 300px;
    left: 0;
    margin-left: 0;
  }
}

.slider .carousel-wrapper .slider-text {
  height: 100%;
  padding-bottom: 10px;
  text-align: right;
}

@media (max-width: 576px) {
  .slider .carousel-wrapper .slider-text {
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

.slider .carousel-wrapper .slider-text p {
  color: #fff;
}

.slider .carousel-wrapper .slider-text .btn-white {
  border: 2px solid #fff;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 14px;
}

@media (max-width: 767px) {
  .slider .carousel-wrapper .slider-text .btn-white {
    margin-bottom: 2.5rem;
  }
}

.slider .carousel-wrapper .slider-text .btn-white:hover,
.slider .carousel-wrapper .slider-text .btn-white:active {
  color: #000;
  background: #fff;
}

.slider .carousel-wrapper .img-carousel {
  width: 100%;
}

@media (max-width: 767px) {
  .slider .carousel-wrapper .img-carousel {
    width: 100%;
  }
}

.slider .carousel-wrapper.imageTopRight img {
  height: auto;
  top: 2px;
  right: 2px;
}

.slider .carousel-wrapper.imageTopRight .slider-text-container {
  padding-left: 24px;
  padding-right: 24px;
}

.slider .carousel-wrapper.reverse .slider-text-container {
  padding-left: 24px;
  padding-right: 24px;
}

.slider .carousel-wrapper.reverse .slider-text {
  max-width: 264px;
  text-align: left;
}

@media (max-width: 576px) {
  .slider .carousel-wrapper.reverse .slider-text {
    text-align: center;
    max-width: unset;
  }
}

.has-phoenixBg {
  background-image: url("../images/ec_phoenix_bg.jpg");
}

.has-cryptobg {
  background-image: url("../images/cryptobg.jpg");
}

.has-claimDustBg {
  background-image: url("../images/dust-carousel-banner_bg.png");
  background-position: top center;
}

.has-plutoAllianceBg.slider .carousel-wrapper .slider-image img {
  left: 0;
}

#ether-cards {
  padding: 0;
}

.os-banner {
  display: none;
  width: 100%;
  line-height: 48px;
  text-align: center;
  background-color: #be1fda;
  color: #fff;
  position: fixed;
  top: 0;
  z-index: 10200;
}

.os-banner a {
  margin-left: .5rem;
  color: #fff;
  font-weight: 600;
  transition: color .3s ease-out;
}

.os-banner a:hover {
  color: #d2d2d2;
  text-decoration: none;
}

.os-banner a img {
  max-height: 24px;
  margin-right: .25rem;
}

.os-banner .close-btn {
  position: absolute;
  right: 2rem;
  cursor: pointer;
  font-weight: 700;
}

.os-banner-visible header .os-banner {
  display: block;
}

.os-banner-visible header nav {
  top: 48px;
}

.os-banner-visible main {
  margin-top: 148px;
}

.offset-1andhalf {
  margin-left: 12%;
}

@media (max-width: 768px) {
  .offset-1andhalf {
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .offset-lg-half {
    margin-right: 4%;
  }
}

@media (max-width: 992px)and (min-width: 768px) {
  .carousel-wrapper.reverse img {
    height: 80%;
  }
}

@media (max-width: 550px) {
  .os-banner {
    line-height: 24px;
  }
}

.imageContanier {
  height: 100%;
}

@media (max-width: 1190px)and (min-width: 750px) {
  .has-claimDustBg .slide {
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .imageContanier {
    height: 85%;
  }
}

